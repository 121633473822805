<!--
 * @Description: 咨询回复
 * @Author: ChenXueLin
 * @Date: 2021-12-15 14:24:15
 * @LastEditTime: 2022-07-15 15:51:44
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="questionType">
              <el-input
                v-model="searchForm.questionType"
                placeholder="问题类型"
                title="问题类型"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="specificItem">
              <el-input
                v-model="searchForm.specificItem"
                placeholder="具体项目"
                title="具体项目"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="questionContext">
              <el-input
                v-model="searchForm.questionContext"
                placeholder="问题内容"
                title="问题内容"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createdByName">
              <el-input
                v-model="searchForm.createdByName"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="handleAdd"></i>
          <i class="e6-icon-clear_line" title="删除" @click="handleDelete"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 添加一级服务类型 start -->
    <el-dialog
      v-dialogDrag
      title="添加回复时限"
      :visible.sync="addDialog"
      width="500px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="editCustomerDialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="edit-customer-dialog"
    >
      <el-form
        ref="addForm"
        label-width="120px"
        :model="addForm"
        :rules="addFormRules"
      >
        <el-form-item label="问题类型" prop="questionType">
          <el-input
            v-model.trim="addForm.questionType"
            placeholder="问题类型"
          ></el-input>
        </el-form-item>
        <el-form-item label="具体项目" prop="specificItem">
          <el-input
            v-model.trim="addForm.specificItem"
            placeholder="具体项目"
          ></el-input>
        </el-form-item>
        <el-form-item label="问题内容" prop="questionContext">
          <el-input
            v-model.trim="addForm.questionContext"
            placeholder="问题内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="回复时限" prop="replyTime">
          <el-input
            type="number"
            v-model.trim="addForm.replyTime"
            placeholder="回复时限"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model.trim="addForm.remark"
            placeholder="备注"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getReplyList, deleteReply, createReply } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "replyManage",
  components: {},
  mixins: [listPage, listPageReszie, base],
  data() {
    return {
      editCustomerDialogLoading: false,
      loading: false,
      total: 0,
      searchForm: {
        questionType: "", //问题类型
        specificItem: "", //具体项目
        questionContext: "", //问题内容
        createdByName: "", //创建人
        createTime: [],
        beginTimeTimestamp: "", //查询开始时间时间戳类型
        endTimeTimestamp: "", //查询结束时间时间戳类型
        pageIndx: 1,
        pageSize: 20
      },
      selColumnId: [],
      columnData: [
        {
          fieldName: "questionType",
          display: true,
          fieldLabel: "问题类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "specificItem",
          display: true,
          fieldLabel: "具体项目",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "questionContext",
          display: true,
          fieldLabel: "问题内容",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "replyTime",
          display: true,
          fieldLabel: "回复时限(小时)",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdByName",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTimeString",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      addDialog: false,
      addForm: {
        questionType: "", //问题类型
        specificItem: "", //具体项目
        questionContext: "", //问题内容
        replyTime: "", //回复时限
        remark: "" //备注
      },
      addFormRules: {
        questionType: [
          {
            required: true,
            message: "请输入问题类型",
            trigger: ["blur", "change"]
          }
        ],
        specificItem: [
          {
            required: true,
            message: "请输入具体项目",
            trigger: ["blur", "change"]
          }
        ],
        questionContext: [
          {
            required: true,
            message: "请输入问题内容",
            trigger: ["blur", "change"]
          }
        ],
        replyTime: [
          {
            required: true,
            message: "请输入回复时限",
            trigger: ["blur", "change"]
          }
        ]
      },
      refTable: "tableList",
      queryListAPI: getReplyList
    };
  },
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.beginTimeTimestamp = val[0] || "";
        this.searchForm.endTimeTimestamp = val[1] || "";
      }
    }
  },
  created() {
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return Object.assign(
          {},
          {
            id: item.limitId,
            versionNumber: item.versionNumber
          }
        );
      });
    },
    //批量删除
    handleDelete() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteReplyReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //批量删除
    async deleteReplyReq() {
      try {
        this.loading = true;
        let res = await deleteReply({ ids: this.selColumnId });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /******新增*******/

    handleAdd() {
      this.addDialog = true;
    },
    handleConfirm() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.addReply();
        }
      });
    },
    //创建请求
    async addReply() {
      try {
        this.loading = true;
        let res = await createReply(this.addForm);
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.queryList();
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.addForm = {
        questionType: "", //问题类型
        specificItem: "", //具体项目
        questionContext: "", //问题内容
        replyTime: "", //回复时限
        remark: "" //备注
      };
      this.$refs.addForm.resetFields();
      this.addDialog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-dialog__body {
    padding: 20px 20px 0;
    .el-form {
      .el-input {
        width: 220px;
      }
    }
  }
}
</style>
